'use strict';

module.exports = {
    toggleChangePasswordVisibility: function () {
        $('img.password-visibility-icon').on('click', function () {
            var passwordInput = $(this).prev();
            console.log(passwordInput)
            var visibleImg = $(this).attr('data-visible');
            var notVisibleImg = $(this).attr('data-notvisible');

            if (passwordInput.attr('type') === 'password') {
                passwordInput.attr('type', 'text');
                $(this).attr('src', notVisibleImg);
            } else {
                passwordInput.attr('type', 'password');
                $(this).attr('src', visibleImg);
            }
        });
    }
};
